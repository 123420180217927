import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ErrorNotice from "../misc/errorNotice";
import Loader from "../misc/loader";
import { REGISTER_USER } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import { ToastContainer, toast } from "react-toastify";
// import $ from "jquery";
// Purpose: Register User Page
// Created By: RP
function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState();
  const [validationErrors, setValidationErrors] = useState([]);
  const history = useHistory();
  // useEffect(() => {
  //   $("#btnSignUp").hide();
  //   $("#btnLogin").show();
  // }, []);

  //Register user submit event
  const submit = async (e) => {
    setError("");
    e.preventDefault();
    let errors = [];
    //firstname
    if (firstName.trim() === "") {
      errors.push("firstname");
    }

    if (lastName.trim() === "") {
      errors.push("lastname");
    }

    if (email.trim() === "") {
      errors.push("email");
    }

    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    } else {
      try {
        setLoader("block");
        createUser();
      } catch (err) {
        setLoader("");
        err.message && setError(err.message);
      }
    }
  };

  const [createUser] = useMutation(REGISTER_USER, {
    update(_, { data: { registerUser } }) {
      if (registerUser) {
        toast.success(
          "User created successfully, please check your email to set password.",
          {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => {
              setLoader("");
              history.push("/login");
            },
          }
        );
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      // console.log(JSON.parse(JSON.stringify(error)).message.split(":")[1])
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
      //setError("Register Failed");
    },
    variables: {
      firstName: firstName,
      lastName: lastName,
      email: email,
    },
  });

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  return (
    <div className="container">
      <h2>Register</h2>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}

      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-md-6">
            {" "}
            <div className="form-group">
              <label htmlFor="firstname">First Name </label>
              <input
                type="text"
                id="firstname"
                className={
                  hasError("firstname")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("firstname") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div className="form-group">
              <label htmlFor="lastname">Last Name </label>
              <input
                type="text"
                id="lastname"
                className={
                  hasError("lastname")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("lastname") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="emailaddress">Email</label>
              <input
                type="email"
                id="emailaddress"
                className={
                  hasError("email") ? "form-control is-invalid" : "form-control"
                }
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("email") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title="Register"
            >
              Register
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );
}

export default Register;
