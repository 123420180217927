import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
// Purpose: Authenticate User and Show Header Links
// Created By: RP
function AuthOptions() {
  const { user, logout } = useContext(UserContext);
  const history = useHistory();

  const register = () => history.push("/register");

  const logoutClick = () => {
    logout();
    history.push("/login");
  };

  const loginClick = () => {
    history.push("/login");
  };
  return (
    <div className="float-right w-100">
      {user ? (
        <Navbar.Collapse id="basic-navbar-nav" className="float-right">
          <Nav className="mr-auto">
            <ul className="navbar-nav mr-auto">
              <li className="navbar-item">
                <Link id="aUsers" to="/" className="nav-link">
                  Users
                </Link>
              </li>
              <li className="navbar-item">
                <Link id="aCustomers" to="/customers" className="nav-link">
                  Customers
                </Link>
              </li>
              <li className="navbar-item">
                <Link id="aBrochures" to="/brochures" className="nav-link">
                  Brochures
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  id="aChangePassword"
                  to="/changepassword"
                  className="nav-link"
                >
                  Change Password
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  id="aSiteConfiguration"
                  to="/siteconfiguration"
                  className="nav-link"
                >
                  Site Configuration
                </Link>
              </li>
              <li className="navbar-item">
                <button className="btn btn-danger mr-2" onClick={logoutClick}>
                  Logout
                </button>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      ) : (
        // <Navbar.Collapse id="basic-navbar-nav" className="float-right">
        //   <Nav className="mr-auto">
        //     <button className="btn btn-secondary m-2" onClick={register} id="btnSignUp">
        //       Sign Up
        //     </button>
        //     <button className="btn btn-secondary m-2" onClick={loginClick} id="btnLogin">
        //       Login
        //     </button>
        //   </Nav>
        // </Navbar.Collapse>
        ""
      )}
    </div>
  );
}

export default AuthOptions;
