import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { LOGIN_USER } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import "./signin.css";
import loginimg from "../../content/digital-brochure-logo-new.svg";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../misc/loader";
// import $ from "jquery";
// Purpose: Login Page
// Created By: RP

function Login() {
  const [emailaddress, setEmailaddress] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState();
  const context = useContext(UserContext);
  const history = useHistory();

  // useEffect(() => {
  //   $("#btnLogin").hide();
  //   $("#btnSignUp").show();
  // }, []);

  const [login] = useMutation(LOGIN_USER, {
    update(_, { data: { login: userData } }) {
      if (userData) {
        context.login(userData);
        history.push("/");
      } else {
        toast.error("Invalid Credential Or user not found!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
      console.log(JSON.parse(JSON.stringify(error)).message);
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      emailaddress: emailaddress,
      password: password,
    },
  });
  //Login User submit event
  const submit = async (e) => {
    e.preventDefault();
    if (emailaddress.trim() === "" || password.trim() === "") {
      if (emailaddress === "") {
        toast.error("Please enter Email", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (password.trim() === "") {
        toast.error("Please enter password", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setLoader("block");
      login();
    }
  };

  return (
    <div className="container text-center">
      {loader && <Loader display={loader} />}
      <main className="form-signin">
        <form onSubmit={submit} className="justify-content-center">
          <img className="mb-4" src={loginimg} alt="" width="72" height="57" />
          <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

          <div className="form-floating">
            <input
              type="email"
              id="emailaddress"
              className="form-control"
              placeholder="name@example.com"
              onChange={(e) => setEmailaddress(e.target.value)}
              maxLength="100"
            />
            {/* <label for="emailaddress">Email address</label> */}
          </div>

          <div className="form-floating">
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              maxLength="100"
            />
            {/* <label for="password">Password</label> */}
          </div>

          <button
            className="w-100 btn btn-lg btn-primary cmscolor"
            type="submit"
            title="Sign In"
          >
            Sign In
          </button>
          <div style={{ marginTop: "10px" }}>
            <Link to="/forgotpassword">Forgot Password?</Link>
          </div>
        </form>
      </main>
      <ToastContainer limit={1} />
    </div>
  );
}

export default Login;
