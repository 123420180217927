import React, { useState, useEffect, useContext, Component } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorNotice from "../misc/errorNotice";
// import ToastNotice from "../misc/toastNotice";
import { UserContext } from "../../context/userContext";
import {
  DELETE_USER,
  ALL_USERS_MUTATION,
  FORGOT_PASSWORD,
} from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
//Used for Confirmation befor delete
import { confirmAlert } from "react-confirm-alert";
//CSS for Confirmation box
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import $ from "jquery";
import Loader from "../misc/loader";
// Purpose: User List
// Created By: RP

//Repeat of User row
// const Users = (props) => {
//   return (
//     <tr>
//       <td>{props.user.firstName}</td>
//       <td>{props.user.lastName}</td>
//       <td>{props.user.email}</td>
//       <td className="text-center">
//         <Link to={"/edituser/" + props.user.id}>edit</Link>
//         {props.user.id !== props.loggedinId ? (
//           <span>
//             &nbsp;|&nbsp;
//             <a
//               href="#"
//               onClick={() => {
//                 props.deleteUser(props.user.id);
//               }}
//             >
//               delete
//             </a>
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//     </tr>
//   );
// };

//Get User list
function UsersList() {
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const [users, setUsers] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [currentEmailId, setCurrentEmailId] = useState("");
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [gridstate, setGridState] = useState({
    sort: [{ field: "id", dir: "asc" }],
    skip: 0,
    take: 10,
  });
  if (!localStorage.getItem("auth-token")) {
    window.location.href = "/login";
  }
  // const { loading, data: { allUsers } = [], refetch } = useQuery(ALL_USERS);

  //Check user is Loggedin or not
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) {
      window.location.href = "/login";
    } else {
      $(".navbar-collapse ul li a").each(function () {
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aUsers").addClass("nav-link active");
      setLoader("block");
      allUser();
    }
  }, []);

  const [allUser] = useMutation(ALL_USERS_MUTATION, {
    update(_, { data: { allUsers } }) {
      setLoader("");
      if (allUsers) {
        setUsers(allUsers);
      } else {
        setUsers([]);
      }
    },
    onError(error) {
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader("");
    },
  });

  // const usersList = () => {
  //   return users.map((currentuser, index) => {
  //     return (
  //       <Users
  //         user={currentuser}
  //         deleteUser={deleteUsers}
  //         loggedinId={user.id}
  //         key={currentuser.id}
  //       />
  //     );
  //   });
  // };

  const deleteUsers = (id) => {
    setCurrentId(id);
    confirmAlert({
      title: "Are you sure?",
      message: "You want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoader("block");
            deleteUser();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [deleteUser] = useMutation(DELETE_USER, {
    update(_, { data: { deleteUser } }) {
      if (deleteUser) {
        const deleteData = deleteUser.split("|");
        if (deleteData[0] === "1") {
          toast.success(deleteData[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
          setUsers(users.filter((el) => el.id !== currentId));
        } else {
          toast.error(deleteData[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
    },
    variables: {
      id: parseInt(currentId),
      deletedBy: user ? parseInt(user.id) : 0,
    },
  });

  const ResetPassword = (email) => {
    setLoader("block");
    setCurrentEmailId(email);
    ResetPasswordMutation();
  };

  const [ResetPasswordMutation] = useMutation(FORGOT_PASSWORD, {
    update(_, { data: { forgotPassword } }) {
      if (forgotPassword) {
        toast.success(forgotPassword, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
    },
    variables: {
      email: currentEmailId,
    },
  });

  const handleGridDataStateChange = (e) => {
    setGridState(e.dataState);
  };

  const kendoActionCell = (props) => {
    return (
      <td className="text-center">
        <span>
         
          <a
            title="Reset Password"
            href="#"
            onClick={() => {
              ResetPassword(props.dataItem["email"]);
            }}
          >
            <span className="k-icon k-i-hyperlink-open"></span>
          </a>
          &nbsp;|&nbsp;
        </span>

        <Link to={"/edituser/" + props.dataItem["id"]} title="Edit">
          <span className="k-icon k-i-edit"></span>
        </Link>
        {props.dataItem["id"] !== user.id ? (
          <span>
            &nbsp;|&nbsp;
            <a
              title="Delete"
              href="#"
              onClick={() => {
                deleteUsers(props.dataItem["id"]);
              }}
            >
              <span className="k-icon k-i-delete"></span>
            </a>
          </span>
        ) : (
          ""
        )}

        
      </td>
    );
  };

  const create = () => history.push("/createuser");

  return (
    <div className="container gridcontainer">
      <h4 className="mt-3 mb-3">
        User List
        <div className="float-right mb-3 ">
          <button
            type="button"
            className="btn btn-primary cmscolor"
            onClick={create}
            title="Create"
          >
            Create
          </button>
        </div>
      </h4>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}

      {/* {toast && <ToastNotice toast={toast} />} */}

      {/* <table className="table">
        <thead className="thead-light">
          <tr>
            <th>Name</th>
            <th>Emailaddress</th>
            <th>Status</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            usersList()
          ) : (
            <tr>
              <td className="text-center" colSpan="4">
                No User found!
              </td>
            </tr>
          )}
        </tbody>
      </table> */}
      <Grid
        data={process(users, gridstate)}
        sortable={true}
        reorderable={true}
        filterable={true}
        pageable={{ buttonCount: 4, pageSizes: true }}
        {...gridstate}
        onDataStateChange={handleGridDataStateChange}
        style={{ height: "500px", width: "100%" }}
      >
        <GridColumn field="firstName" title="First Name" />
        <GridColumn field="lastName" title="Last Name" />
        <GridColumn field="email" title="Email" />
        <GridColumn
          title="Action"
          className="text-center"
          cell={kendoActionCell}
          filterable={false}
          headerCell={CenterHeader}
        />
      </Grid>
      <ToastContainer limit={1} />
    </div>
  );
}

class CenterHeader extends Component {
  render() {
    return <span className="k-link text-center">{this.props.title}</span>;
  }
}

export default UsersList;
