import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import "../../components/auth/signin.css";
import loginimg from "../../content/digital-brochure-logo-new.svg";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../misc/loader";
// Purpose: Forgot Password
// Created By: RP

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [loader, setLoader] = useState();
  const [SendRequest] = useMutation(FORGOT_PASSWORD, {
    update(_, { data: { forgotPassword } }) {
      setLoader("");
      if (forgotPassword) {
        toast.success(forgotPassword, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => history.push("/login"),
          autoClose: 2000,
        });
        //history.push("/login");
      } else {
        toast.error("Email not found!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
    onError(error) {
      setLoader("");
      toast.error("Email not found", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      email: email.trim(),
    },
  });

  //Login User submit event
  const submit = async (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      toast.error("Please enter Email", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLoader("block");
      SendRequest();
    }
  };

  return (
    
    <div className="container text-center">
         {loader && <Loader display={loader} />}
      <main className="form-signin">
        <form onSubmit={submit} className="justify-content-center">
          <img className="mb-4" src={loginimg} alt="" width="72" height="57" />
          <h1 className="h3 mb-3 fw-normal">Forgot Password?</h1>

          <div className="form-floating">
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="name@example.com"
              style={{ borderRadius: "5px" }}
              onChange={(e) => setEmail(e.target.value)}
              maxLength="100"
            />
          </div>

          <button
            className="w-100 btn btn-lg btn-primary cmscolor"
            type="submit"
            style={{ marginTop: "15px" }}
            title="Submit"
          >
            Submit
          </button>
        </form>
      </main>
      <ToastContainer limit={1} />
    </div>
  );
}

export default ForgotPassword;
