import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import ErrorNotice from "../misc/errorNotice";
import Loader from "../misc/loader";
import { ToastContainer, toast } from "react-toastify";
import { CHANGE_PASSWORD } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

// Purpose: CHange Password
// Created By: RP
function ChangePassword(props) {
  const [currentpassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [validationErrors, setValidationErrors] = useState([]);
  const [currentpasswordShown, setCurrentPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordCheckShown, setPasswordCheckShown] = useState(false);
  //Check user is Loggedin or not
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) {
      window.location.href = "/login";
    }
    $(".navbar-collapse ul li a").each(function () {
      $(this).removeClass("active");
      $(this).addClass("nav-link");
    });
    $("#aChangePassword").addClass("nav-link active");
  }, []);

  //User Submit Event
  const submit = async (e) => {
    setError("");
    e.preventDefault();

    let errors = [];
    if (currentpassword.trim() === "") {
      errors.push("currentpassword");
    }
    if (password.trim() === "") {
      errors.push("password");
    }
    if (passwordCheck.trim() === "") {
      errors.push("confirmpassword");
    }

    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    } else {
      try {
        if (password !== passwordCheck) {
          setError("Password and Confirm password must be same!");
          return;
        }

        if (user) {
          setLoader("block");
          changePassword();
        }
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg);
      }
    }
  };

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    update(_, { data: { changePassword } }) {
      if (changePassword) {
        toast.success(changePassword, {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => history.push("/"),
          autoClose: 1500,
        });
      } else {
        setError("Oops something went wrong, please try again");
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: user ? parseInt(user.id) : 0,
      password: password.trim(),
      currentpassword: currentpassword.trim(),
    },
  });

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  const togglecurrentPasswordVisiblity = () => {
    setCurrentPasswordShown(currentpasswordShown ? false : true);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordCheckVisiblity = () => {
    setPasswordCheckShown(passwordCheckShown ? false : true);
  };

  return (
    <div className="container">
      <div className="page-header mt-3 mb-3">
        <h4> Change Password</h4>
      </div>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="password">Current Password</label>
              <div className="pass-wrapper">
                <input
                  type={currentpasswordShown ? "text" : "password"}
                  id="currentpassword"
                  className={
                    hasError("currentpassword")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Enter Current Password"
                  autoComplete="new-password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <i onClick={togglecurrentPasswordVisiblity}>
                  {currentpasswordShown ?  eye : eyeSlash}
                </i>
              </div>
              <div
                style={{
                  display: hasError("currentpassword") ? "block" : "none",
                }}
                className={
                  hasError("currentpassword")
                    ? "invalid-feedback"
                    : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="pass-wrapper">
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  className={
                    hasError("password")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Enter Password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i onClick={togglePasswordVisiblity}>
                  {passwordShown ?  eye : eyeSlash}
                </i>
              </div>
              <div
                style={{
                  display: hasError("password") ? "block" : "none",
                }}
                className={
                  hasError("password") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="pass-wrapper">
                <input
                  type={passwordCheckShown ? "text" : "password"}
                  id="confirmPassword"
                  className={
                    hasError("confirmpassword")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  autoComplete="new-password"
                  placeholder="Enter Confirm Password"
                  onChange={(e) => setPasswordCheck(e.target.value)}
                  maxLength="100"
                />
                <i onClick={togglePasswordCheckVisiblity}>
                  {passwordCheckShown ?  eye : eyeSlash}
                </i>
              </div>
              <div
                style={{
                  display: hasError("confirmpassword") ? "block" : "none",
                }}
                className={
                  hasError("confirmpassword")
                    ? "invalid-feedback"
                    : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title="Update"
            >
              Update
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );
}

export default ChangePassword;
