import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import ErrorNotice from "../misc/errorNotice";
import { ToastContainer, toast } from "react-toastify";
import { FETCH_User_ResetCode, RESET_PASSWORD } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import Loader from "../misc/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;
// Purpose: CHange Password
// Created By: RP
function ResetPassword(props) {
  const [issetPW, setSetPW] = useState(window.location.pathname.indexOf("resetpassword") !== -1 ? false : true);
  const [resetCode, setResetCode] = useState(props.match.params.resetCode);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const history = useHistory();
  const { user, logout } = useContext(UserContext);
  const [validationErrors, setValidationErrors] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordCheckShown, setPasswordCheckShown] = useState(false);

  //Check user is Loggedin or not
  useEffect(() => {
    if (user) {
      logout();
      window.location.href = window.location.href;
    } else {
      fetchUserByResetCode();
    }
  }, []);

  const [fetchUserByResetCode] = useMutation(FETCH_User_ResetCode, {
    update(_, { data: { fetchUserByResetCode } }) {
      if (fetchUserByResetCode) {
        setResetCode(fetchUserByResetCode);
      } else {
        toast.error("No request found with this code, please try again", {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => history.push("/forgotpassword"),
        });
      }
    },
    onError(error) {
      //setError("Error");
      toast.error("No request found with this code, please try again", {
        position: toast.POSITION.TOP_RIGHT,
        onClose: () => history.push("/forgotpassword"),
      });
    },
    variables: {
      resetCode: resetCode,
    },
  });

  //User Submit Event
  const submit = async (e) => {
    e.preventDefault();

    let errors = [];

    if (password.trim() === "") {
      errors.push("password");
    }
    if (passwordCheck.trim() === "") {
      errors.push("confirmpassword");
    }

    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    } else {
      try {
        if (password.trim() !== passwordCheck.trim()) {
          setError("Password and Confirm password must be same!");
          return;
        }
        setLoader("block");
        resetPassword();
      } catch (err) {
        setLoader("");
        err.message && setError(err.message);
      }
    }
  };

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    update(_, { data: { resetPassword } }) {
     
      if (resetPassword) {
        toast.success(
          issetPW ? "Password set successully!" : "Password reset successully!"
          , {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => history.push("/"),
        });
      } else {
        setError("Oops something went wrong, please try again");
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      resetCode: resetCode,
      password: password.trim(),
    },
  });

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordCheckVisiblity = () => {
    setPasswordCheckShown(passwordCheckShown ? false : true);
  };

  return (
    <div className="container">
      <div className="page-header mt-3 mb-3">
        <h4> {issetPW ? "Set Password" : "Reset Password"}</h4>
      </div>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
        {loader && <Loader display={loader} />}
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="pass-wrapper">
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  className={
                    hasError("password")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Enter Password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength="100"
                />
                <i onClick={togglePasswordVisiblity}>
                  {passwordShown ?  eye : eyeSlash}
                </i>
              </div>
              <div
                style={{
                  display: hasError("password") ? "block" : "none",
                }}
                className={
                  hasError("password") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="pass-wrapper">
                <input
                  type={passwordCheckShown ? "text" : "password"}
                  id="confirmPassword"
                  className={
                    hasError("confirmpassword")
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  autoComplete="new-password"
                  placeholder="Enter Confirm Password"
                  onChange={(e) => setPasswordCheck(e.target.value)}
                  maxLength="100"
                />
                <i onClick={togglePasswordCheckVisiblity}>
                  {passwordCheckShown ?  eye : eyeSlash}
                </i>
              </div>
              <div
                style={{
                  display: hasError("confirmpassword") ? "block" : "none",
                }}
                className={
                  hasError("confirmpassword")
                    ? "invalid-feedback"
                    : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title={issetPW ? "Set Password" : "Reset Password"}
            >
              {issetPW ? "Set Password" : "Reset Password"}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );
}

export default ResetPassword;
