import React, { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";

//Purpose : Use for getting loggedin user details
const initialState = {
  user: null,
};

if (localStorage.getItem("auth-token")) {
  const decodedToken = jwtDecode(localStorage.getItem("auth-token"));
  if (decodedToken.exp * 24000 < Date.now()) {
    localStorage.removeItem("auth-token");
  } else {
    initialState.user = {
      id: decodedToken.id,
      email: decodedToken.email,
      firstName: decodedToken.firstName,
      token: localStorage.getItem("auth-token"),
    };
  }
}

const UserContext = createContext({
  user: null,
  login: (userData) => {},
  logout: () => {},
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {

  const [state, dispatch] = useReducer(authReducer, initialState);
  function login(userData) {
    localStorage.setItem("auth-token", userData.token);
    dispatch({
      type: "LOGIN",
      payload: userData.user,
    });
  }
  function logout() {
    localStorage.removeItem("auth-token");
    dispatch({
      type: "LOGOUT",
    });
  }

  return (
    <UserContext.Provider
      value={{ user: state.user, login, logout }}
      {...props}
    />
  );
}

export { UserContext, AuthProvider };
