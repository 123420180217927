import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import ErrorNotice from "./errorNotice";
import Loader from "./loader";
import { ToastContainer, toast } from "react-toastify";
import { SITECONFIG_ADDUPDATE, GET_SITECONFIG } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import $ from "jquery";
// Purpose: SiteConfiguration
// Created By: RP
function SiteConfiguration(props) {
  const [fromName, setFromName] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [validationErrors, setValidationErrors] = useState([]);

  //Check user is Loggedin or not
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) {
      window.location.href = "/login";
    } else {
      fetchSiteConfig();
      $(".navbar-collapse ul li a").each(function () {
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aSiteConfiguration").addClass("nav-link active");
    }
  }, []);

  const [fetchSiteConfig] = useMutation(GET_SITECONFIG, {
    update(_, { data: { getSiteConfiguration } }) {
      if (getSiteConfiguration) {
        setFromName(getSiteConfiguration.fromName);
        setFromEmail(getSiteConfiguration.fromEmail);
        setAdminEmail(getSiteConfiguration.adminEmail);
      } else {
        setFromName("");
        setFromEmail("");
        setAdminEmail("");
      }
    },
    onError(error) {
      //setError("Error");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  //User Submit Event
  const submit = async (e) => {
    setError("");
    e.preventDefault();

    let errors = [];

    //firstname
    if (fromEmail.trim() === "") {
      errors.push("fromEmail");
    }

    if (fromName.trim() === "") {
      errors.push("fromName");
    }

    if (adminEmail.trim() === "") {
      errors.push("adminEmail");
    }
    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    } else {
      try {
        setLoader("block");
        createOrupdateSiteConfig();
      } catch (err) {
        setLoader("");
        err.response.data.msg && setError(err.response.data.msg);
      }
    }
  };

  const [createOrupdateSiteConfig] = useMutation(SITECONFIG_ADDUPDATE, {
    update(_, { data: { createOrUpdate } }) {
      if (createOrUpdate) {
        toast.success(createOrUpdate, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setError("Oops something went wrong, please try again");
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      fromName: fromName.trim(),
      fromEmail: fromEmail.trim(),
      adminEmail: adminEmail.trim(),
    },
  });

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  return (
    <div className="container">
      <div className="page-header mt-3 mb-3">
        <h4> Site Configuration</h4>
      </div>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="fromName"> From Email Name </label>
              <input
                type="text"
                id="fromName"
                className={
                  hasError("fromName")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter From Email Name"
                value={fromName}
                onChange={(e) => setFromName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("fromName") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          {" "}
          <div className="col-md-4">
            {" "}
            <div className="form-group">
              <label htmlFor="fromEmail">From Email </label>
              <input
                type="email"
                id="fromEmail"
                className={
                  hasError("fromEmail")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter From Email"
                value={fromEmail}
                onChange={(e) => setFromEmail(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("fromEmail") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            {" "}
            <div className="form-group">
              <label htmlFor="adminEmail">Admin Email</label>
              <input
                type="email"
                id="adminEmail"
                value={adminEmail}
                className={
                  hasError("adminEmail")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter Admin Email"
                onChange={(e) => setAdminEmail(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("adminEmail") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title="Submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );
}

export default SiteConfiguration;
