import React, { useState } from "react";

// Purpose: Show Error message
// Created By: RP
function Loader(props) {
 
  const [show, setShow] = useState(props.display ? props.display : "none");
  //console.log(show)
  return (
    <div id="loading" className="loding_autoheader" style={{display:show}}>
      <img alt="Loading..." id="loading" src="/embedded/code/full/images/loading.gif" />
    </div>
  );
}

export default Loader;
