import React from "react";

// Purpose: Include routing related package
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import components
import Header from "./components/layout/header";
import Home from "./components/misc/home";
import Register from "./components/auth/register";
import Login from "./components/auth/login";
import { AuthProvider } from "./context/userContext";
import Customers from "./components/customers/customers-list";
import CreateCustomer from "./components/customers/create-customer";
import Brochures from "./components/brochures/brochures-list";
import CreateBrochure from "./components/brochures/create-brochure";
import CreateUser from "./components/users/create-user";
import ChangePassword from "./components/misc/changepassword";
import ForgotPassword from "./components/misc/forgotpassword";
import ResetPassword from "./components/misc/resetpassword";
import SiteConfiguration from "./components/misc/siteconfiguration";
import NotFound from "./components/misc/pageNotFound";

import "./App.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/customers" component={Customers} />
          <Route path="/brochures" component={Brochures} />
          <Route path="/createuser" component={CreateUser} />
          <Route path="/edituser/:id" component={CreateUser} />
          <Route path="/createcustomer" component={CreateCustomer} />
          <Route path="/editcustomer/:id" component={CreateCustomer} />
          <Route path="/createbrochure" component={CreateBrochure} />
          <Route path="/editbrochure/:id" component={CreateBrochure} />
          <Route path="/changepassword" component={ChangePassword} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/setpassword/:resetCode" component={ResetPassword} />
          <Route path="/resetpassword/:resetCode" component={ResetPassword} />
          <Route path="/siteconfiguration" component={SiteConfiguration} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
