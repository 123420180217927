import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import ErrorNotice from "../misc/errorNotice";
import Loader from "../misc/loader";
import { ToastContainer, toast } from "react-toastify";
import {
  CREATE_Customer,
  UPDATE_Customer,
  FETCH_Customer_ID,
} from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import $ from "jquery";
// Purpose: Create Customer
// Created By: RP
function CreateCustomer(props) {
  const [id, setId] = useState(props.match.params.id);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [county, setCounty] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState([]);
  useEffect(() => {
    //Check user is Loggedin or not
    if (!localStorage.getItem("auth-token")) {
      window.location.href="/login";
    } else {
      if (id) {
        fetchCustomer();
      }
      $(".navbar-collapse ul li a").each(function () {
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aCustomers").addClass("nav-link active");
    }
  }, []);

  //Edit Customer details for Update Customer
  const [fetchCustomer] = useMutation(FETCH_Customer_ID, {
    update(_, { data: { fetchCustomer } }) {
      if (fetchCustomer) {
        setName(fetchCustomer.name);
        setEmail(fetchCustomer.email);
        setAddressLine1(fetchCustomer.addressLine1);
        setAddressLine2(fetchCustomer.addressLine2);
        setCounty(fetchCustomer.county);
        setCity(fetchCustomer.city);
        setState(fetchCustomer.state);
        setCountry(fetchCustomer.country);
        setPostCode(fetchCustomer.postCode);
        setShortCode(fetchCustomer.shortCode);
        setIsActive(fetchCustomer.isActive);
      } else {
        setName("");
        setEmail("");
        setAddressLine1("");
        setAddressLine2("");
        setCounty("");
        setState("");
        setCity("");
        setCountry("");
        setPostCode("");
        setShortCode("");
        setIsActive("");
      }
    },
    onError(error) {
      // setError("Error");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
    },
  });

  //Customer Submit Event
  const submit = async (e) => {
    setError("");
    e.preventDefault();

    let errors = [];

    if (name.trim() === "") {
      errors.push("name");
    }
    // if (email.trim() === "") {
    //   errors.push("email");
    // }

    if (shortCode.trim() === "") {
      errors.push("shortcode");
    }
    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    } else {
      try {
        setLoader("block");
        if (id) {
          updateCustomer();
        } else {
          createCustomer();
        }
      } catch (err) {
        setLoader("");
        err.response.data.msg && setError(err.response.data.msg);
      }
    }
  };

  const [updateCustomer] = useMutation(UPDATE_Customer, {
    update(_, { data: { updateCustomer } }) {
      if (updateCustomer) {
        toast.success("Customer details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            setLoader("");
            history.push("/customers");
          },
          autoClose: 1500,
        });
        //history.push("/customers");
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
      name: name.trim(),
      email: email.trim(),
      addressLine1: addressLine1.trim(),
      addressLine2: addressLine2.trim(),
      city: city.trim(),
      state: state.trim(),
      county: county.trim(),
      country: country.trim(),
      postCode: postCode.trim(),
      shortCode: shortCode.trim(),
      isActive: isActive,
      updatedBy: user ? parseInt(user.id) : 0,
    },
  });

  const [createCustomer] = useMutation(CREATE_Customer, {
    update(_, { data: { addCustomer } }) {
      if (addCustomer) {
        toast.success("Customer details created successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            setLoader("");
            history.push("/customers");
          },
          autoClose: 1500,
        });
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      name: name.trim(),
      email: email.trim(),
      addressLine1: addressLine1.trim(),
      addressLine2: addressLine2.trim(),
      city: city.trim(),
      state: state.trim(),
      county: county.trim(),
      country: country.trim(),
      postCode: postCode.trim(),
      shortCode: shortCode.trim(),
      isActive: isActive,
      createdBy: user ? parseInt(user.id) : 0,
    },
  });

  const handleActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  const back = () => history.push("/customers");

  return (
    <div className="container">
      <div className="page-header mt-3 mb-3">
        <div className="btn-toolbar float-right">
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={back}
              title="Back"
            >
              Back
            </button>
          </div>
        </div>
        <h4>{id ? "Update Customer" : "Create Customer"}</h4>
      </div>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="name">Name </label>
              <input
                type="text"
                id="name"
                className={
                  hasError("name") ? "form-control is-invalid" : "form-control"
                }
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("name") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="shortCode">Short Code </label>
              <input
                type="text"
                id="shortCode"
                className={
                  hasError("shortcode")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter Short Code"
                value={shortCode}
                onChange={(e) => setShortCode(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("shortcode") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="email">Email </label>
              <input
                type="email"
                id="email"
                className={
                  hasError("email") ? "form-control is-invalid" : "form-control"
                }
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                maxLength="100"
              />
              {/* <div
                className={
                  hasError("email") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div> */}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="addressLine1">Address Line 1 </label>
              <input
                type="text"
                id="addressLine1"
                className="form-control"
                placeholder="Enter Address Line 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="addressLine2">Address Line 2 </label>
              <input
                type="text"
                id="addressLine2"
                className="form-control"
                placeholder="Enter Address Line 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="county">County </label>
              <input
                type="text"
                id="county"
                className="form-control"
                placeholder="Enter County"
                value={county}
                onChange={(e) => setCounty(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="city">City </label>
              <input
                type="text"
                id="city"
                className="form-control"
                placeholder="Enter City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="state">State </label>
              <input
                type="text"
                id="state"
                className="form-control"
                placeholder="Enter State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="country">Country </label>
              <input
                type="text"
                id="country"
                className="form-control"
                placeholder="Enter Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="postCode">Eircode </label>
              <input
                type="text"
                id="postCode"
                className="form-control"
                placeholder="Enter Eircode"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
                maxLength="100"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="isActive" style={{ margin: "0" }}>
                Active
              </label>
              <input
                type="checkbox"
                id="isActive"
                className="form-control"
                style={{
                  width: "20px",
                  boxShadow: "none",
                  margin: "8px 0 0 0",
                }}
                checked={isActive ? "checked" : ""}
                onChange={handleActiveChange}
                maxLength="100"
              />
            </div>
          </div>
        </div>
        {/* <div className="form-row">
         </div> */}
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title={id ? "Update" : "Create"}
            >
              {id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );

  // toggleChange = () => {
  //   this.setIsActive(!isActive);
  // }
}

export default CreateCustomer;
