import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import ErrorNotice from "../misc/errorNotice";
import { ToastContainer, toast } from "react-toastify";
import {
  UPDATE_USER_BYID,
  CREATE_USER,
  FETCH_USER_BY_MUTATION_ID,
} from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import $ from "jquery";
import Loader from "../misc/loader";

// Purpose: Create user
// Created By: RP
function CreateUser(props) {
  const [id, setId] = useState(props.match.params.id);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [validationErrors, setValidationErrors] = useState([]);

  //Check user is Loggedin or not
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) {
      window.location.href = "/login";
    } else {
      if (id) {
        fetchUser();
      }
      $(".navbar-collapse ul li a").each(function () {
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aUsers").addClass("nav-link active");
    }
  }, []);


  const [fetchUser] = useMutation(FETCH_USER_BY_MUTATION_ID, {
    update(_, { data: { fetchUser } }) {
      if (fetchUser) {
        setFirstName(fetchUser.firstName);
        setLastName(fetchUser.lastName);
        setEmail(fetchUser.email);
      } else {
        setFirstName("");
        setLastName("");
        setEmail("");
      }
    },
    onError(error) {
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
    },
  });

  //User Submit Event
  const submit = async (e) => {
    setError("");
    e.preventDefault();

    let errors = [];

    //firstname
    if (firstName.trim() === "") {
      errors.push("firstname");
    }

    if (lastName.trim() === "") {
      errors.push("lastname");
    }

    if (email.trim() === "") {
      errors.push("email");
    }

    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    } else {
      try {
        if (id) {
          setLoader("block");
          updateUser();
        } else {
          setLoader("block");
          createUser();
        }
      } catch (err) {
        err.response.data.msg && setError(err.response.data.msg);
      }
    }
  };

  const [updateUser] = useMutation(UPDATE_USER_BYID, {
    update(_, { data: { updateUser } }) {
      if (updateUser) {
        toast.success("User details updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            setLoader("");
            history.push("/");
          },
          autoClose: 1500,
        });
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      updatedBy: user ? parseInt(user.id) : 0,
    },
  });

  const [createUser] = useMutation(CREATE_USER, {
    update(_, { data: { createUser } }) {
      if (createUser) {
        toast.success("User details created successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => {
            setLoader("");
            history.push("/");
          },
          autoClose: 1500,
        });
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      createdBy: user ? parseInt(user.id) : 0,
    },
  });

  const back = () => history.push("/");

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  // const handleInputChange = (event) => {
  //   var key = event.target.name;
  //   var value = event.target.value;
  //   var obj = {};
  //   obj[key] = value;
  //   setState(obj);
  // };
  return (
    <div className="container">
      <div className="page-header mt-3 mb-3">
        <div className="btn-toolbar float-right">
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={back}
              title="Back"
            >
              Back
            </button>
          </div>
        </div>
        <h4> {id ? "Update User" : "Create User"}</h4>
      </div>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}
      <form onSubmit={submit}>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstname">First Name </label>
              <input
                type="text"
                id="firstname"
                className={
                  hasError("firstname")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("firstname") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div className="form-group">
              <label htmlFor="lastname">Last Name </label>
              <input
                type="text"
                id="lastname"
                className={
                  hasError("lastname")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("lastname") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {" "}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                className={
                  hasError("email") ? "form-control is-invalid" : "form-control"
                }
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("email") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
        </div>
        
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title={id ? "Update" : "Create"}
            >
              {id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );
}

export default CreateUser;
