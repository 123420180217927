import React, { useContext } from "react";
import AuthOptions from "../auth/authOptions";
import { UserContext } from "../../context/userContext";
import { Navbar } from "react-bootstrap";

// Purpose: Header Page
// Created By: RP
function Header() {
  const { user } = useContext(UserContext);

  return (
    <Navbar bg="dark" expand="lg" className="navbar-dark">
      <Navbar.Brand href="/">
        Digital Brochure{" "}
        {user ? (
          <small className="form-text">Welcome {user.firstName}</small>
        ) : (
          ""
        )}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <AuthOptions />
    </Navbar>
  );
}

export default Header;
