import React from "react";
import App from "./App";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import "./index.css";
//Include bootstarp CSS
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import '@progress/kendo-theme-default/dist/all.css';
import axios from "axios";
// import { createUploadLink } from "apollo-upload-client";
import { createHttpLink } from "apollo-link-http";
import { SERVER_API_URL } from "./util/config";
//API server base URL
axios.defaults.baseURL = SERVER_API_URL + "/";

const authLink = setContext(() => {
  const token = localStorage.getItem("auth-token");

  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: SERVER_API_URL + "/graphql",
  headers: {
    "keep-alive": "true",
  },
});

// const uploadLink = createUploadLink({
//   uri: process.env.SERVER_API_URL + "/graphql",
//   headers: {
//     "keep-alive": "true",
//   },
// });

const client = new ApolloClient({
  //link: authLink.concat(uploadLink),
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
