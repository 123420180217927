import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation login($emailaddress: String!, $password: String!) {
    login(email: $emailaddress, password: $password) {
      token
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const ALL_USERS = gql`
  query allUsers {
    allUsers {
      id
      firstName
      lastName
      email
    }
  }
`;

export const FETCH_USER_BYID = gql`
  query fetchUser($id: Int!) {
    fetchUser(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ALL_USERS_MUTATION = gql`
  mutation allUsers {
    allUsers {
      id
      firstName
      lastName
      email
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!, $deletedBy: Int!) {
    deleteUser(id: $id, deletedBy: $deletedBy)
  }
`;

export const FETCH_USER_BY_MUTATION_ID = gql`
  mutation fetchUser($id: Int!) {
    fetchUser(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_USER_BYID = gql`
  mutation updateUser(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $updatedBy: Int!
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      updatedBy: $updatedBy
    ) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $createdBy: Int!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      createdBy: $createdBy
    ) {
      id
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser(
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    registerUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      id
    }
  }
`;

export const CREATE_Customer = gql`
  mutation addCustomer(
    $name: String!
    $email: String!
    $addressLine1: String
    $addressLine2: String
    $county: String
    $state: String
    $city: String
    $country: String
    $postCode: String
    $shortCode: String
    $isActive: Boolean
    $createdBy: Int!
  ) {
    addCustomer(
      name: $name
      email: $email
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      county: $county
      state: $state
      city: $city
      country: $country
      postCode: $postCode
      shortCode: $shortCode
      isActive: $isActive
      createdBy: $createdBy
    ) {
      id
    }
  }
`;

export const UPDATE_Customer = gql`
  mutation updateCustomer(
    $id: Int!
    $name: String!
    $email: String!
    $addressLine1: String
    $addressLine2: String
    $county: String
    $state: String
    $city: String
    $country: String
    $postCode: String
    $shortCode: String
    $isActive: Boolean
    $updatedBy: Int!
  ) {
    updateCustomer(
      id: $id
      name: $name
      email: $email
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      county: $county
      state: $state
      city: $city
      country: $country
      postCode: $postCode
      shortCode: $shortCode
      isActive: $isActive
      updatedBy: $updatedBy
    ) {
      id
    }
  }
`;

export const FETCH_Customer_ID = gql`
  mutation fetchCustomer($id: Int!) {
    fetchCustomer(id: $id) {
      id
      name
      email
      addressLine1
      addressLine2
      county
      state
      city
      country
      postCode
      shortCode
      isActive
    }
  }
`;

export const FETCH_ALL_Customer = gql`
  mutation allCustomer {
    allCustomer {
      id
      name
      email
      uniqueCode
      addressLine1
      addressLine2
      county
      state
      city
      country
      postCode
      shortCode
      isActive
    }
  }
`;

export const DELETE_Customer = gql`
  mutation deleteCustomer($id: Int!, $deletedBy: Int!) {
    deleteCustomer(id: $id, deletedBy: $deletedBy)
  }
`;

export const UPLOAD_BROCHURE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;

export const CREATE_BROCHURE = gql`
  mutation addBrochure(
    $name: String!
    $description: String
    $CustomerId: Int!
    $isPasswordProtected: Boolean
    $password: String
    $isActive: Boolean
    $createdBy: Int!
  ) {
    addBrochure(
      name: $name
      description: $description
      CustomerId: $CustomerId
      isPasswordProtected: $isPasswordProtected
      password: $password
      isActive: $isActive
      createdBy: $createdBy
    ) {
      id
    }
  }
`;

export const UPDATE_BROCHURE = gql`
  mutation updateBrochure(
    $id: Int!
    $name: String!
    $description: String
    $CustomerId: Int!
    $isPasswordProtected: Boolean
    $password: String
    $isActive: Boolean
    $updatedBy: Int!
  ) {
    updateBrochure(
      id: $id
      name: $name
      description: $description
      CustomerId: $CustomerId
      isPasswordProtected: $isPasswordProtected
      password: $password
      isActive: $isActive
      updatedBy: $updatedBy
    ) {
      id
    }
  }
`;

export const DELETE_BROCHURE = gql`
  mutation deleteBrochure($id: Int!, $deletedBy: Int!) {
    deleteBrochure(id: $id, deletedBy: $deletedBy)
  }
`;

export const FETCH_BROCHURE_ID = gql`
  mutation fetchBrochure($id: Int!) {
    fetchBrochure(id: $id) {
      id
      name
      description
      CustomerId
      fileName
      isPasswordProtected
      isActive
    }
  }
`;

export const FETCH_ALL_BROCHURE = gql`
  mutation allBrochure {
    allBrochure {
      id
      name
      description
      uniqueBrochureCode
      Customer {
        id
        name
        uniqueCode
      }
      fileName
      uniqueFileName
      uniqueFileHeight
      isPasswordProtected
      isActive
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $id: Int!
    $currentpassword: String!
    $password: String!
  ) {
    changePassword(
      id: $id
      currentpassword: $currentpassword
      password: $password
    )
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const FETCH_User_ResetCode = gql`
  mutation fetchUserByResetCode($resetCode: String!) {
    fetchUserByResetCode(resetCode: $resetCode)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetCode: String!, $password: String!) {
    resetPassword(resetCode: $resetCode, password: $password)
  }
`;

export const SITECONFIG_ADDUPDATE = gql`
  mutation createOrUpdate(
    $fromName: String!
    $fromEmail: String!
    $adminEmail: String!
  ) {
    createOrUpdate(
      fromName: $fromName
      fromEmail: $fromEmail
      adminEmail: $adminEmail
    )
  }
`;

export const GET_SITECONFIG = gql`
  mutation getSiteConfiguration {
    getSiteConfiguration {
      fromName
      fromEmail
      adminEmail
    }
  }
`;

export const FETCH_ALL_Customer_DRP = gql`
  mutation allCustomerDRP($id: Int) {
    allCustomerDRP(id: $id) {
      id
      name
    }
  }
`;