import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import ErrorNotice from "../misc/errorNotice";
import Loader from "../misc/loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  CREATE_BROCHURE,
  UPDATE_BROCHURE,
  FETCH_BROCHURE_ID,
  FETCH_ALL_Customer_DRP,
} from "../../util/graphQL";
import $ from "jquery";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { PUBLIC_URL, SERVER_API_URL } from "../../util/config";
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

// Purpose: Create Brochure
// Created By: RP
function CreateBrochure(props) {
  const [file, setFile] = useState(null);
  const [id, setId] = useState(props.match.params.id);
  const [Customers, setCustomers] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [CustomerId, setCustomerId] = useState(0);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);

  const [allCustomers] = useMutation(FETCH_ALL_Customer_DRP, {
    update(_, { data: { allCustomerDRP } }) {
      if (allCustomerDRP) {
        setCustomers(allCustomerDRP);
      } else {
        setCustomers([]);
      }
    },
    onError(error) {
      //setError("Error");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
    },
  });

  //Edit Brochure details for Update Brochure
  const [fetchBrochure] = useMutation(FETCH_BROCHURE_ID, {
    update(_, { data: { fetchBrochure } }) {
      if (fetchBrochure) {
        setName(fetchBrochure.name);
        setDescription(fetchBrochure.description);
        setCustomerId(fetchBrochure.CustomerId);
        setIsPasswordProtected(fetchBrochure.isPasswordProtected);
        setIsActive(fetchBrochure.isActive);
      } else {
        setName("");
        setDescription("");
        setCustomerId(0);
        setIsPasswordProtected(false);
        setPassword("");
        setIsActive("");
      }
    },
    onError(error) {
      // setError("Error");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
    },
  });

  useEffect(() => {
    //Check user is Loggedin or not
    if (!localStorage.getItem("auth-token")) {
      window.location.href="/login";
    } else {
      setId(props.match.params.id);
      allCustomers();
      if (id) {
        fetchBrochure();
      }
      $(".navbar-collapse ul li a").each(function () {
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aBrochures").addClass("nav-link active");
    }
  }, []);

  //Brochure Submit Event
  const submit = async (e) => {
    setError("");
    e.preventDefault();

    let errors = [];

    if (name.trim() === "") {
      errors.push("name");
    }

    if (CustomerId <= 0 || CustomerId == null || CustomerId === "") {
      errors.push("Customer");
    }

    if(!id)
    {
    if (isPasswordProtected && password.trim() === "") {
      errors.push("password");
    }
  }

    setValidationErrors(errors);
    if (errors.length > 0) {
      return;
    } else {
      try {
        const PasswordRegex = /^([^\s\<\>]{6,})$/;
        if (isPasswordProtected && password.trim() !== "") {
          const resultPW = PasswordRegex.test(password.trim());
          if (!resultPW && isPasswordProtected) {
            setError("Invalid Password");
            return;
          }
        }
        setLoader("block");
        if (id) {
          updateBrochure();
        } else {
          if (!file) {
            setLoader("");
            setError("Please upload PDF file for Brochure");
            return;
          }

          createBrochure();
        }
      } catch (err) {
        err.message && setError(err.message);
        setLoader("");
      }
    }
  };

  const [updateBrochure] = useMutation(UPDATE_BROCHURE, {
    update(_, { data: { updateBrochure } }) {
      if (updateBrochure) {
        try {
          if (file) {
            UploadBrochure(updateBrochure.id);
          } else {
            // history.push("/brochures");
            toast.success("Brochure details updated successfully.", {
              position: toast.POSITION.TOP_RIGHT,
              onClose: () => {
                setLoader("");
                history.push("/brochures");
              },
              autoClose: 1500,
            });
          }
        } catch (err) {
          history.push("/brochures");
        }
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      id: parseInt(id),
      name: name.trim(),
      description: description.trim(),
      CustomerId: parseInt(CustomerId),
      isPasswordProtected: isPasswordProtected,
      password: password.trim(),
      isActive: isActive,
      updatedBy: user ? parseInt(user.id) : 0,
    },
  });

  const [createBrochure] = useMutation(CREATE_BROCHURE, {
    update(_, { data: { addBrochure } }) {
      if (addBrochure) {     
        if (file) {
          UploadBrochure(addBrochure.id);
        } else {
          toast.success("Brochure details created successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => {
              setLoader("");
              history.push("/brochures");
            },
            autoClose: 1500,
          });
          // history.push("/brochures");
        }
      } else {
        setLoader("");
        setError("Oops something went wrong, please try again");
      }
    },
    onError(error) {
      setLoader("");
      // setError(error + "createBrochure2");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    variables: {
      name: name.trim(),
      description: description.trim(),
      CustomerId: parseInt(CustomerId),
      isPasswordProtected: isPasswordProtected,
      password: password.trim(),
      isActive: isActive,
      createdBy: user ? parseInt(user.id) : 0,
    },
  });

  const UploadBrochure = async (brochureid) => {
    try {
      const formData = new FormData();
      formData.append("file", file); // appending file
      formData.append("id", brochureid); // appending file
      await axios
        .post(
          "brochures/uploadBrochure",
          formData,
          {
            headers: { "x-auth-token": user ? user.token : "" },
          },
          {
            onUploadProgress: (ProgressEvent) => {
              // let progress =
              //   Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
              //   "%";
              // setProgess(progress);
            },
          }
        )
        .then((res) => {
          const URLOpen = `${PUBLIC_URL}/embedded/index-db.html?1/${res.data.msg}`;
          windowOpen(URLOpen);
          if (id) {
            toast.success("Customer details updated successfully.", {
              position: toast.POSITION.TOP_RIGHT,
              onClose: () => {
                setLoader("");
                history.push("/brochures");
              },
              autoClose: 1500,
            });
          } else {
            toast.success("Customer details created successfully.", {
              position: toast.POSITION.TOP_RIGHT,
              onClose: () => {
                setLoader("");
                history.push("/brochures");
              },
              autoClose: 1500,
            });
          }

          //console.log(res);
          //history.push("/brochures");
        })
        .catch((err) => {
          setLoader("");
          setError(err);
        });
    } catch (err) {
      history.push("/brochures");
    }
  };

  const windowOpen = (URLOpen) =>{
   // setTimeout(function(){
      window.open(URLOpen, 'newwindow', 'toolbar=0,status=0,width=5,height=5', "_blank");
    //},1000)
    
  }

  const handleChange = () => {
    setIsPasswordProtected(!isPasswordProtected);
  };
  // const [createFile] = useMutation(UPLOAD_BROCHURE, {
  //   update(_, { data: { uploadFile } }) {},
  //   onError(error) {
  //     setError(error + "createFile");
  //   },
  // });

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    //console.log("This file size is: " + file.size / 1024 / 1024 + "MiB");
    if (file.type === "application/pdf") {
      var Size = file.size / 1024 / 1024;
      if (Size > 50) {
        setError("Please upload PDF file upto 50MB size only");
      } else {
        setError("");
        setFile(file);
      }
    } else {
      setError("Please upload only PDF file");
    }
    //createFile({ variables: { file } });
  };

  const CustomersList = () => {
    return Customers.map((currentCustomer) => {
      return (
        <option value={currentCustomer.id} key={currentCustomer.id}>
          {currentCustomer.name}
        </option>
      );
    });
  };

  const hasError = (key) => {
    return validationErrors.indexOf(key) !== -1;
  };

  const back = () => history.push("/brochures");

  const handleActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="container">
      <div className="page-header mt-3 mb-3">
        <div className="btn-toolbar float-right">
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-primary"
              onClick={back}
              title="Back"
            >
              Back
            </button>
          </div>
        </div>
        <h4>{id ? "Update Brochure" : "Create Brochure"}</h4>
      </div>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}
      {loader && <Loader display={loader} />}
      <form onSubmit={submit} encType={"multipart/form-data"}>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Customer </label>
              <select
                className={
                  hasError("Customer")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                id="Customer"
                value={CustomerId}
                onChange={(e) => setCustomerId(e.target.value)}
              >
                <option value="0">Select Customer</option>
                {CustomersList()}
              </select>
              <div
                className={
                  hasError("Customer") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="name">Name </label>
              <input
                type="text"
                id="name"
                className={
                  hasError("name") ? "form-control is-invalid" : "form-control"
                }
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength="100"
              />
              <div
                className={
                  hasError("name") ? "invalid-feedback" : "valid-feedback"
                }
              >
                Required
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="description">Description </label>
              <textarea
                type="text"
                id="description"
                className="form-control"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength="250"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="Brochure">Brochure </label>
              <input
                className="form-control-file"
                name={"document"}
                type={"file"}
                // ref={(ref) => {
                //   setUploadInput(ref ? ref.files[0] : {});
                // }}
                // onChange={({ target: {validity, files } }) => {
                //   // const file = files[0];
                //   setUploadInput({
                //     selectedDocument: {
                //       file: files[0],
                //     },
                //     selectedFile: files[0],
                //   })
                //   // file && singleUploadStream({ variables: { file: file } })
                // }}
                onChange={handleUploadImage}
              />
              <small id="brochureHelp" className="form-text text-danger">
                Please upload only .PDF file upto 50MB size
              </small>
              {/* <button onClick={handleUploadImage}>Upload</button> */}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="isPasswordProtected" style={{ margin: "0" }}>
                Is Password Protected
              </label>
              <div className="form-group" style={{ margin: "11px 0 0 0" }}>
                <input
                  type="checkbox"
                  id="isPasswordProtected"
                  className="form-control"
                  style={{
                    width: "20px",
                    boxShadow: "none",
                    display: "inline-block",
                    float: "left",
                  }}
                  checked={isPasswordProtected ? "checked" : ""}
                  onChange={handleChange}
                />

                {isPasswordProtected ? (
                  <span className="">
                    <div className="pass-wrapper">
                      <input
                        style={{
                          width: "94%",
                          margin: "0 0 0 10px",
                          display: "inline-block",
                        }}
                        type={passwordShown ? "text" : "password"}
                        id="password"
                        className={
                          hasError("password")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        minLength="6"
                        maxLength="100"
                      />
                      <i
                        style={{ right: "30px" }}
                        onClick={togglePasswordVisiblity}
                      >
                        {passwordShown ?  eye : eyeSlash}
                      </i>
                    </div>

                    <div
                      style={{
                        marginLeft: "30px",
                        display: hasError("password") ? "block" : "none",
                      }}
                      className={
                        hasError("password")
                          ? "invalid-feedback"
                          : "valid-feedback"
                      }
                    >
                      Required
                    </div>
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="isActive" style={{ margin: "0" }}>
                Active
              </label>
              <input
                type="checkbox"
                id="isActive"
                className="form-control"
                style={{
                  width: "20px",
                  boxShadow: "none",
                  margin: "11px 0 0 0",
                }}
                checked={isActive ? "checked" : ""}
                onChange={handleActiveChange}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <button
              type="submit"
              className="btn btn-primary cmscolor"
              title={id ? "Update" : "Create"}
            >
              {id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer limit={1} />
    </div>
  );
}

export default CreateBrochure;
