import React, { useState, useEffect, useContext, Component } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorNotice from "../misc/errorNotice";
import Loader from "../misc/loader";
import { UserContext } from "../../context/userContext";
//Used for Confirmation befor delete
import { confirmAlert } from "react-confirm-alert";
//CSS for Confirmation box
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import {
  FETCH_ALL_Customer,
  DELETE_Customer,
} from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";

import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import $ from 'jquery';

// Purpose: Customer List
// Created By: RP

//Repeat of Customer row
// const Customers = (props) => (
//   <tr>
//     <td>{props.Customer.name}</td>
//     <td>{props.Customer.shortCode}</td>
//     <td>{props.Customer.addressLine1}</td>
//     <td>{props.Customer.addressLine2}</td>
//     <td>{props.Customer.country}</td>
//     <td>{props.Customer.postCode}</td>
//     <td className="text-center">
//       {props.Customer.isActive ? (
//         <span className="badge badge-primary">YES</span>
//       ) : (
//         <span className="badge badge-danger">NO</span>
//       )}
//     </td>
//     <td className="text-center">
//       <Link to={"/editcustomer/" + props.Customer.id}>edit</Link> |{" "}
//       <a
//         href="#"
//         onClick={() => {
//           props.deleteCustomer(props.Customer.id);
//         }}
//       >
//         delete
//       </a>
//     </td>
//   </tr>
// );

//Get Customer list
function CustomersList() {
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const [Customers, setCustomers] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [gridstate, setGridState] = useState({
    sort: [{ field: "id", dir: "asc" }],
    skip: 0,
    take: 10,
  });
  //Check user is Loggedin or not
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) {
      window.location.href="/login";
    } else {
      setLoader("block");
      allCustomers();
      $(".navbar-collapse ul li a").each(function(){
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aCustomers").addClass("nav-link active");
    }
  }, []);

  const [allCustomers] = useMutation(FETCH_ALL_Customer, {
    update(_, { data: { allCustomer } }) {
      if (allCustomer) {
        setCustomers(allCustomer);
      } else {
        setCustomers([]);
      }
      setLoader("");
    },
    onError(error) {
      // setError("Error");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader("");
    },
  });

  // const CustomersList = () => {
  //   return Customers.map((currentCustomer) => {
  //     return (
  //       <Customers
  //         Customer={currentCustomer}
  //         deleteCustomer={deleteCustomers}
  //         key={currentCustomer.id}
  //       />
  //     );
  //   });
  // };

  const deleteCustomers = (id) => {
    setCurrentId(id);
    confirmAlert({
      title: "Are you sure?",
      message: "You want to delete this Customer?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoader("block");
            deleteCustomer();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [deleteCustomer] = useMutation(DELETE_Customer, {
    update(_, { data: { deleteCustomer } }) {
      setLoader("");
      if(deleteCustomer){
        const deleteData = deleteCustomer.split('|');
        if(deleteData[0] === '1')
        {
          toast.success(deleteData[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCustomers(Customers.filter((el) => el.id !== currentId));
        }
        else{
          toast.error(deleteData[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    onError(error) {
      setLoader("");
    },
    variables: {
      id: parseInt(currentId),
      deletedBy: user ? parseInt(user.id) : 0,
    },
  });

  const handleGridDataStateChange = (e) => {
    setGridState(e.dataState);
  };

  const create = () => history.push("/createcustomer");

  const kendoActionCell = (props) => {
    //  console.log(props)
    return (
      <td className="text-center">
        <Link to={"/editcustomer/" + props.dataItem["id"]}  title="Edit"><span className="k-icon k-i-edit"></span></Link> | {""}
        <a
         title="Delete"
          href="#"
          onClick={() => {
            deleteCustomers(props.dataItem["id"]);
          }}
        >
          <span className="k-icon k-i-delete"></span>
        </a>
      </td>
    );
  };

  const checkboxColumn = (props) => {
    return (
      <td className="text-center">
        {props.dataItem[props.field] ? (
          <span className="badge badge-primary">YES</span>
        ) : (
          <span className="badge badge-danger">NO</span>
        )}
        {/* <input type="checkbox" className="form-control" checked={this.props.dataItem[this.props.field]} disabled="disabled" /> */}
      </td>
    );
  };

  return (
    <div className="container gridcontainer">
      <h4 className="mt-3 mb-3">
        Customer List
        <div className="float-right mb-3 ">
          <button
            type="button"
            className="btn btn-primary cmscolor"
            onClick={create}
            title="Create"
          >
            Create
          </button>
        </div>
      </h4>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}

{loader && (
        <Loader display={loader} />
      )}

      {/* <table className="table">
        <thead className="thead-light">
          <tr>
            <th>Name</th>
            <th>Short Code</th>
            <th>Address Line 1</th>
            <th>Address Line 2</th>
            <th>Country</th>
            <th>PostCode</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {Customers.length > 0 ? (
            CustomersList()
          ) : (
            <tr>
              <td className="text-center" colSpan="8">
                No Customer found!
              </td>
            </tr>
          )}
        </tbody>
      </table> */}

      <Grid
        data={process(Customers, gridstate)}
        sortable={true}
        reorderable={true}
        filterable={true}
        pageable={{ buttonCount: 4, pageSizes: true }}
        {...gridstate}
        onDataStateChange={handleGridDataStateChange}
        style={{ height: "500px", width: "100%" }}
      >
        <GridColumn field="name" title="Name" />
        <GridColumn field="shortCode" title="Short Code" />
        <GridColumn field="email" title="Email" />
        {/* <GridColumn field="addressLine1" title="AddressLine1" />
        <GridColumn field="addressLine2" title="AddressLine2" />
        <GridColumn field="county" title="County" /> */}
        <GridColumn field="city" title="City" />
        {/* <GridColumn field="state" title="State" /> */}
        {/* <GridColumn field="country" title="Country" /> */}
        <GridColumn field="postCode" title="Eircode" />
        <GridColumn
          field="isActive"
          title="Active"
          cell={checkboxColumn}
          filterable={false}
          headerCell={CenterHeader}
        />
        <GridColumn
          title="Action"
          className="text-center"
          cell={kendoActionCell}
          filterable={false}
          headerCell={CenterHeader}
        />
      </Grid>
      <ToastContainer limit={1} />
    </div>
  );
}

class CenterHeader extends Component {
  render() {
    return <span className="k-link text-center">{this.props.title}</span>;
  }
}

export default CustomersList;
