import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  Component,
} from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorNotice from "../misc/errorNotice";
import Loader from "../misc/loader";
import { UserContext } from "../../context/userContext";
//Used for Confirmation befor delete
import { confirmAlert } from "react-confirm-alert";
//CSS for Confirmation box
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import { FETCH_ALL_BROCHURE, DELETE_BROCHURE } from "../../util/graphQL";
import { useMutation } from "@apollo/react-hooks";
import { PUBLIC_URL, SERVER_API_URL } from "../../util/config";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import $ from "jquery";
// Purpose: Brochures List
// Created By: RP

//Get Brochure list
function BrochuresList() {
  const [error, setError] = useState();
  const [loader, setLoader] = useState();
  const [brochures, setBrochures] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [gridstate, setGridState] = useState({
    sort: [{ field: "id", dir: "asc" }],
    skip: 0,
    take: 10,
  });
  const textAreaRef = useRef(null);
  //Check user is Loggedin or not
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) {
      window.location.href="/login";
    } else {
      setLoader("block");
      allBrochures();
      $(".navbar-collapse ul li a").each(function () {
        $(this).removeClass("active");
        $(this).addClass("nav-link");
      });
      $("#aBrochures").addClass("nav-link active");
    }
  }, []);

  const [allBrochures] = useMutation(FETCH_ALL_BROCHURE, {
    update(_, { data: { allBrochure } }) {
      if (allBrochure) {
        setBrochures(allBrochure);
      } else {
        setBrochures([]);
      }
      setLoader("");
    },
    onError(error) {
      setLoader("");
      //setError("Error");
      toast.error(JSON.parse(JSON.stringify(error)).message.split(":")[1], {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
  });

  const deleteBrochures = (id) => {
    setCurrentId(id);
    confirmAlert({
      title: "Are you sure?",
      message: "You want to delete this Brochure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setLoader("block");
            deleteBrochure();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const copyToClipboard = (brochure) => {
    const htmlCopy =
      `<div id="dbembedded"></div>
    <script src="` +
      PUBLIC_URL +
      `/embedded/code/full/js/libs/jquery.min.js"></script>
    <script type="text/javascript">
      var _UniqueId = [{ uniqueBrochureCode: "` +
      brochure.uniqueBrochureCode +
      `" }];
      (function () {
        var db = document.createElement("script");
        db.type = "text/javascript";
        db.async = true;
        db.src =
          "` +
      PUBLIC_URL +
      `/embedded/index.min.js?v=1.0";
        var scr = document.getElementsByTagName("script")[0];
        scr.parentNode.insertBefore(db, scr);
      })();
    </script>`;
    textAreaRef.current.value = htmlCopy;
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    toast.success("Code Copied!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const copyToClipboardShareableURL = (brochure) => {
    const htmlCopy = `${PUBLIC_URL}/embedded?${brochure.Customer.name.replace(" ","_")}/${brochure.name.replace(" ","_")}/${brochure.uniqueBrochureCode}`;
    textAreaRef.current.value = htmlCopy;
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    toast.success("URL Copied!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const copyToClipboardShareableiFrameURL = (brochure) => {
    console.log(brochure)
    const htmlURL = `${PUBLIC_URL}/embedded?${brochure.Customer.name.replace(" ","_")}/${brochure.name.replace(" ","_")}/${brochure.uniqueBrochureCode}`;
    const htmlCopy =`<iframe  id="iframeembedded"  scrolling="no" frameborder="0" style="width: 100%; border: none; height: ${brochure.uniqueFileHeight ? brochure.uniqueFileHeight + 'px' : '100vh'};" src="${htmlURL}"></iframe>`;
    textAreaRef.current.value = htmlCopy;
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    toast.success("iFrame Code Copied!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [deleteBrochure] = useMutation(DELETE_BROCHURE, {
    update(_, { data: { deleteBrochure } }) {
      setLoader("");
      if (deleteBrochure) {
        const deleteData = deleteBrochure.split("|");
        if (deleteData[0] === "1") {
          toast.success(deleteData[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
          setBrochures(brochures.filter((el) => el.id !== currentId));
        } else {
          toast.error(deleteData[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
    onError(error) {
      setLoader("");
    },
    variables: {
      id: parseInt(currentId),
      deletedBy: user ? parseInt(user.id) : 0,
    },
  });

  const handleGridDataStateChange = (e) => {
    setGridState(e.dataState);
  };

  const kendoActionCell = (props) => {
    //  console.log(props)
    return (
      <td className="text-center">
        <Link to={"/editbrochure/" + props.dataItem["id"]} title="Edit">
          <span className="k-icon k-i-edit"></span>
        </Link>{" "}
        | {""}
        <a
          title="Delete"
          href="#"
          onClick={() => {
            deleteBrochures(props.dataItem["id"]);
          }}
        >
          <span className="k-icon k-i-delete"></span>
        </a>
      </td>
    );
  };

  const checkboxColumn = (props) => {
    return (
      <td className="text-center">
        {props.dataItem[props.field] ? (
          <span className="badge badge-primary">YES</span>
        ) : (
          <span className="badge badge-danger">NO</span>
        )}
        {/* <input type="checkbox" className="form-control" checked={this.props.dataItem[this.props.field]} disabled="disabled" /> */}
      </td>
    );
  };

  const PDFViewColumn = (props) => {
    return (
      <td className="text-center">
        <a
          title="View Brochure"
          target="blank"
          href={SERVER_API_URL + "/PDFs/" + props.dataItem[props.field]}
        >
          <span className="k-icon k-i-file-pdf"></span>
        </a>
      </td>
    );
  };

  const embeddedCodeButton = (props) => {
    return (
      <td className="text-center">
        {document.queryCommandSupported("copy") && (
          <div>
            <a
              title="Copy Embedded Code"
              href="#"
              onClick={() => {
                copyToClipboard(props.dataItem);
              }}
            >
              <span className="k-icon k-i-code-snippet"></span>
            </a>
            {" "}
            | {""}
            <a
              title="Copy iFrame Code"
              href="#"
              onClick={() => {
                copyToClipboardShareableiFrameURL(props.dataItem);
              }}
            >
              <span className="k-icon k-i-page-header-section"></span>
            </a>
            {" "}
        | {""}
            <a
              title="Copy Shareable URL"
              href="#"
              onClick={() => {
                copyToClipboardShareableURL(props.dataItem);
              }}
            >
              <span className="k-icon k-i-share"></span>
            </a>

          </div>
          
        )}
      </td>
    );
  };

  const create = () => history.push("/createbrochure");

  return (
    <div className="container gridcontainer">
      <h4 className="mt-3 mb-3">
        Brochure List
        <div className="float-right mb-3 ">
          <button
            type="button"
            className="btn btn-primary cmscolor"
            onClick={create}
            title="Create"
          >
            Create
          </button>
        </div>
      </h4>
      {error && (
        <ErrorNotice message={error} clearError={() => setError(undefined)} />
      )}

      {loader && <Loader display={loader} />}

      
      <Grid
        data={process(brochures, gridstate)}
        sortable={true}
        reorderable={true}
        filterable={true}
        pageable={{ buttonCount: 4, pageSizes: true }}
        {...gridstate}
        onDataStateChange={handleGridDataStateChange}
        style={{ height: "500px", width: "100%" }}
      >
        <GridColumn field="name" title="Name" />
        {/* <GridColumn field="description" title="Description" /> */}
        <GridColumn field="Customer.name" title="Customer" />
        <GridColumn
          field="uniqueFileName"
          title="Brochure "
          filterable={false}
          cell={PDFViewColumn}
          headerCell={CenterHeader}
        />
        <GridColumn
          field="isPasswordProtected"
          title="Is Password Protected"
          cell={checkboxColumn}
          filterable={false}
          headerCell={CenterHeader}
        />
        <GridColumn
          field="isActive"
          title="Active"
          cell={checkboxColumn}
          filterable={false}
          headerCell={CenterHeader}
        />
        <GridColumn
          title="Shareable Code"
          cell={embeddedCodeButton}
          filterable={false}
          headerCell={CenterHeader}
        />
        <GridColumn
          title="Action"
          className="text-center"
          cell={kendoActionCell}
          filterable={false}
          headerCell={CenterHeader}
        />
      </Grid>
      <ToastContainer limit={1} />
      <textarea
        ref={textAreaRef}
        defaultValue="Some text to copy"
        style={{ opacity: "0" }}
      />
    </div>
  );
}

class CenterHeader extends Component {
  render() {
    return <span className="k-link text-center">{this.props.title}</span>;
  }
}

export default BrochuresList;
